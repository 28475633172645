import {Controller} from "@hotwired/stimulus"
import { get, post } from "@rails/request.js"

// Connects to data-controller="catchup-plans"
export default class extends Controller {
  static values = {
    cache: String,
    customer: String,
    guid: String,
    payments: Array,
  }

  paymentPlan = ''
  paymentGuid = ''
  selectedMethod = ''

  connect() {
    const sliderEl = document.querySelector("#catchupSlider")
    const tempSliderValue = sliderEl.value - 1;
    const progress = (tempSliderValue / (sliderEl.max - 1)) * 100;

    sliderEl.style.background = `linear-gradient(to right, #f50 ${progress}%, rgba(0, 0, 0, 0.05) ${progress}%)`;

    let elWidth = ($("#range").width() / (sliderEl.max - 1)) - (12 / (sliderEl.max - 1));

    for (let x = 0; x < sliderEl.max; x++) {
      $("#range").append("<span class='dots' style='left:"+ x * elWidth + "px'></span>");
    }
    this.findStep()
    const paymentGroup = document.querySelector("#paymentGroup")
    const summaryGroup = document.querySelector("#paymentSummary")
    let rentAmount = parseFloat(this.paymentsValue[0]['payments'][0]['rent_amount'])
    let skippedRent = parseFloat(this.paymentsValue[0]['payments'][0]['skipped_rent_amount'])
    let rentTax = parseFloat(this.paymentsValue[0]['payments'][0]['rent_tax_amount'])
    let skippedTax = parseFloat(this.paymentsValue[0]['payments'][0]['skipped_rent_tax_amount'])
    let paymentTotal = rentAmount + skippedRent + rentTax + skippedTax
    const catchupTotal = document.querySelectorAll("#catchupTotal")
    this.paymentPlan = this.paymentsValue[0]['id']
    catchupTotal.forEach(function(item) {
      item.innerHTML = '$' + paymentTotal.toFixed(2)
    })
    paymentGroup.innerHTML = `<div class="d-flex">
                      <div class="m-1 flex-grow-1">
                        ${this.formatDate(this.paymentsValue[0]['payments'][0]['date'])}
                      </div>
                      <div class="m-1">
                        ${'$' + rentAmount.toFixed(2)}
                      </div>
                      <div class="m-1 px-2">+</div>
                      <div class="m-1">
                        ${'$' + skippedRent.toFixed(2)}
                      </div>
                    </div>`
    summaryGroup.innerHTML = `<div class="row my-1">
                                      <div class="col-8 text-left">
                                        ${this.formatDate(this.paymentsValue[0]['payments'][0]['date'])}
                                      </div>
                                      <div class="col-4 text-right">
                                        ${'$' + paymentTotal.toFixed(2)}
                                      </div>
                                    </div>`
  }

  async retrievePaymentMethods() {
    let paymentSelect = document.getElementById('paymentSelect')
    
    if (paymentSelect.options.length > 0) {
      return
    }

    try {
      const response = await get(`/payment_methods?lease_guid=${this.guidValue}`, {
        headers:
            {
              Accept: 'application/json, application/vnd.api+json'
            }
      })

      if (response.ok) {
        let methodArr = await response.json

        await methodArr.forEach(function(item) {
          let option = null

          // We will likely need to expand this for ATH PR
          if (item["type"] === "credit_card") {
            option = document.createElement('option');
            option.value = item["guid"];
            option.text = 'Card ' + item["card_number"];
          } else if (item["type"] === "bank_account") {
            option = document.createElement('option');
            option.value = item["guid"];
            option.text = item["bank_name"] + ' ' + item["account_number"];
          } else {
            return
          }

          paymentSelect.add(option);
        })

        this.paymentGuid = paymentSelect.value
        this.selectedMethod = paymentSelect.options[0].text;
      } else {
        let methodAlert = document.querySelector('#paymentMethodAlert')
        paymentSelect.classList.add('d-none')
        methodAlert.classList.remove('d-none')
      }
    } catch (error) {
      const form = document.querySelector('#catchup-step-form');
      const alert = document.querySelector('#alert-block');
      form.classList.add('d-none');
      alert.classList.remove('d-none');
    }
  }

  updateGuid() {
    let paymentSelect = document.getElementById('paymentSelect')
    this.paymentGuid = paymentSelect.value
    this.selectedMethod = paymentSelect.options[paymentSelect.selectedIndex].text;
  }

  findStep() {
    const multiStepForm = document.querySelector('#catchup-step-form')

    const formSteps = [...multiStepForm.querySelectorAll('[data-step]')]
    let currentStep =  formSteps.findIndex(step => {
      return step.classList.contains('active-form')
    })

    if (currentStep <= 0) {
      currentStep = 0
      formSteps[currentStep].classList.add('active-form');
    }

    return currentStep
  }

  next() {
    let paymentSelect = document.getElementById('paymentSelect')
    const multiStepForm = document.querySelector('#catchup-step-form')
    const formSteps = [...multiStepForm.querySelectorAll('[data-step]')]
    const current_step = this.findStep();

    formSteps[current_step].classList.remove('active-form');
    formSteps[current_step + 1].classList.add('active-form');

    if (paymentSelect) {
      this.retrievePaymentMethods()
    }
  }

  previous() {
    const multiStepForm = document.querySelector('#catchup-step-form')
    const formSteps = [...multiStepForm.querySelectorAll('[data-step]')]

    if (formSteps) {
      const current_step = this.findStep();
      formSteps[current_step].classList.remove('active-form');
      formSteps[current_step - 1].classList.add('active-form');
    }
  }

  async setSubmit() {
    let options = {
      cache_key: this.cacheValue,
      customer_guid: this.customerValue,
      lease_guid: this.guidValue,
      payment_method_guid: this.paymentGuid,
      payment_plan_id: this.paymentPlan
    }

    const response = await post(`/catch_up_plans`, {
      headers: { 'Content-Type': 'application/json' },
      body: options
    })

    if (response.ok) {
      let paymentMethod = document.querySelector('#selectedPaymentMethod');
      paymentMethod.innerHTML = this.selectedMethod
      this.next()
      this.clearLoaderSpinner()
      return response.json;
    } else {
      const form = document.querySelector('#catchup-step-form');
      const alert = document.querySelector('#alert-block');
      form.classList.add('d-none');
      alert.classList.remove('d-none');
      this.clearLoaderSpinner()
      this.next()
    }
  }

  updatePrice() {
    const sliderEl = document.querySelector("#catchupSlider")
    const paymentCount = document.querySelector("#paymentCount")
    const paymentText = document.querySelectorAll("#paymentText")
    const startDate = document.querySelector("#paymentStartDate")
    const taxAmount = document.querySelectorAll("#taxAmount")
    const catchupAmount = document.querySelectorAll("#catchupAmount")
    const catchupTotal = document.querySelectorAll("#catchupTotal")
    const paymentGroup = document.querySelector("#paymentGroup")
    const summaryGroup = document.querySelector("#paymentSummary")
    let tempSliderValue = event.target.value - 1;
    let progress = (tempSliderValue / (sliderEl.max - 1)) * 100;

    sliderEl.style.background = `linear-gradient(to right, #f50 ${progress}%, rgba(0, 0, 0, 0.05) ${progress}%)`;

    const bigTotal = document.querySelector('.big-total')
    let paymentsArr = this.paymentsValue
    let currentPayments = paymentsArr[tempSliderValue]
    let currentPaymentsLength = currentPayments['payments'].length
    this.paymentPlan = currentPayments['id']

    paymentCount.innerHTML = currentPaymentsLength
    if (currentPaymentsLength > 1) {
      paymentText.forEach(function(item) {
        item.innerHTML = currentPaymentsLength + ' Payments';
      })
    } else {
      paymentText.forEach(function(item) {
        item.innerHTML = '1 Payment';
      })
    }

    let rentAmount = parseFloat(currentPayments['payments'][0]['rent_amount'])
    let skippedRent = parseFloat(currentPayments['payments'][0]['skipped_rent_amount'])
    let rentTax = parseFloat(currentPayments['payments'][0]['rent_tax_amount'])
    let skippedTax = parseFloat(currentPayments['payments'][0]['skipped_rent_tax_amount'])
    let paymentTotal = rentAmount + skippedRent + rentTax + skippedTax
    let taxTotal = rentTax + skippedTax

    bigTotal.innerHTML = '$' + rentAmount.toFixed(2)
    startDate.innerHTML = this.formatDate(currentPayments['payments'][0]['date'])
    catchupAmount.forEach(function(item) {
      item.innerHTML = '$' + rentAmount.toFixed(2)
    })
    catchupTotal.forEach(function(item) {
      item.innerHTML = '$' + paymentTotal.toFixed(2)
    })
    taxAmount.forEach(function(item) {
      item.innerHTML = '$' + taxTotal.toFixed(2)
    })

    paymentGroup.innerHTML = ''
    summaryGroup.innerHTML = ''
    for(let x = 0; x < currentPaymentsLength; x++) {
      let paymentDetails = `<div class="d-flex">
                                      <div class="m-1 flex-grow-1">
                                        ${this.formatDate(currentPayments['payments'][x]['date'])}
                                      </div>
                                      <div class="m-1">
                                        ${'$' + rentAmount.toFixed(2)}
                                      </div>
                                      <div class="m-1 px-2">+</div>
                                      <div class="m-1">
                                        ${'$' + skippedRent.toFixed(2)}
                                      </div>
                                    </div>`

      let summaryDetails = `<div class="row my-1">
                                      <div class="col-8 text-left">
                                        ${this.formatDate(currentPayments['payments'][x]['date'])}
                                      </div>
                                      <div class="col-4 text-right">
                                        ${'$' + paymentTotal.toFixed(2)}
                                      </div>
                                    </div>`

      paymentGroup.innerHTML += paymentDetails
      summaryGroup.innerHTML += summaryDetails
    }
  }

  clearLoaderSpinner() {
    $('.loader-overlay').removeClass('d-block');
  }

  formatDate(dateString) {
    let date = new Date(dateString)
    let options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' }

    return date.toLocaleDateString("en-US", options)
  }
}

