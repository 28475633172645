// app/javascript/controllers/payments/inputs.js
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="payment-inputs"
export default class extends Controller {
    connect() {
        this.payment_type = $('#payment-processing-type-options input:radio[name="payment_type"]')
        this.payment_method = $('select#payment_method_guid')
        this.cash_voucher_slideout = $('#cash-voucher-slideout')
        this.reviewButton = $('button[data-target="make-payment-review-button"]')
        this.toggleReviewButton()
    }

    paymentMethodChangeHandler(event){
        const value = event.target.valueOf()
        this.toggleReviewButton()
        this.toggleCashVoucherSlideout()
    }

    paymentAmountChangeHandler(event){
        this.toggleReviewButton()
    }

    otherAmountInputHandler(event){
        $('#other_type').prop('checked', true);
        this.toggleReviewButton()
    }

    formIsValid(){
        return this.payment_type.is(':checked') && this.payment_method.val() != ''
    }

    toggleCashVoucherSlideout(){
        if (this.payment_method.val() && this.payment_method.val().match(/^cash.*/)){
            this.cash_voucher_slideout.slideDown(200)
        } else {
            this.cash_voucher_slideout.slideUp(200)
        }
    }

    toggleReviewButton(){
        if (this.formIsValid()){
            $(this.reviewButton).removeAttr('disabled')
        } else {
            $(this.reviewButton).attr('disabled', true)
        }
    }
}
