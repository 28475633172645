import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="payment-methods--add-method"
export default class extends Controller {
    static values = {
        locale: Object
    }

    bankAccount() {
        let cardAlert = document.getElementById('card-alert');
        let bankForm = document.getElementById('bank-form');
        let cardForm = document.getElementById('card-form');
        let leaseValidationError =  document.getElementById('leaseNumberValidationError')

        cardAlert.classList.remove("d-block");
        bankForm.classList.remove("d-none");
        cardForm.classList.add("d-none");
        if (leaseValidationError) {
            leaseValidationError.innerText = '';
        }
    }

    creditCard() {
        let bankForm = document.getElementById('bank-form');
        let cardAlert = document.getElementById('card-alert');
        let cardForm = document.getElementById('card-form');
        let tx_iframe = document.querySelector('#tx_iframe_ccs_card_formtokenframe_value');
        let ccs_form_url = document.getElementById('ccs_form_url').value;
        cardForm.classList.remove("d-none");

        if (typeof CreditCardServiceV2 == 'undefined') {
            cardAlert.classList.add("d-block");

            let error_message = this.localeValue['card']['error']['ccs_form_failed_to_load'] + ' ' + ccs_form_url
            let error_code = "failed_ccs_form_load"
            let context = {
                error_code: error_code,
                error_message: error_message
            }
            ErrorNotificationService.warning(error_message, context)
        } else {
            if (!tx_iframe) {
                const card_element = document.querySelector('[data-controller="payment-methods--card-form"]');
                const cardForm = this.application.getControllerForElementAndIdentifier(card_element, 'payment-methods--card-form');
                cardForm.prepareCcsForm()
                bankForm.classList.add("d-none");
            } else {
                bankForm.classList.add("d-none");
            }
        }
    }
}