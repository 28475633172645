import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"
import NewPaymentMethodFailureEventService from "../../entrypoints/payment_methods/failure_event"

// Connects to data-controller="payment-methods--add-card"
export default class extends Controller {
    static values = {
        locale: Object,
        leaseGuid: String,
        customerGuid: String
    }

    connect() {
        let localeObj = this.localeValue
        this.observer = new MutationObserver((mutationsList) => {
            mutationsList.forEach((mutation) => {
                if (mutation.type === 'childList') {
                    mutation.addedNodes.forEach((addedNode) => {
                        if (addedNode.nodeType === Node.ELEMENT_NODE) {
                            if (addedNode.id === 'cvvValidationError' && $('#cvvValidationError').text().match(/cvv/i)) {
                                $('#cvvValidationError').text(localeObj['card']['error']['security_code_required']);
                            }
                            if (window.countryCode === 'CA') {
                                if (addedNode.id === 'zipValidationError' && $('#zipValidationError').text().match(/zip/i)) {
                                    $('#zipValidationError').text(localeObj['card']['error']['postal_code_required']);
                                }
                                if (addedNode.id === 'stateValidationError' && $('#stateValidationError').text().match(/state/i)) {
                                    $('#stateValidationError').text(localeObj['card']['error']['providence_required']);
                                }
                            }
                            if (addedNode.classList.contains('ccservice_error')) {
                                addedNode.classList.add('col-sm-5', 'text-danger');
                                if (addedNode.parentElement.childElementCount < 4) {
                                    $("<div class='col-sm-5'></div>").insertBefore(addedNode);
                                }
                            }
                        }
                    });
                }
            });
        });

        this.targetNode = document;
        this.config = { childList: true, subtree: true };
        this.observer.observe(this.targetNode, this.config);
    }

    prepareCcsForm() {
        let cardNumberHeight, cardSettings, ccNumFieldWidth, totalInputLRPadding;
        let state_label = window.countryCode === 'CA' ? this.localeValue['card']['input']['province'] : this.localeValue['card']['input']['state'];
        let zip_label = window.countryCode === 'CA' ? this.localeValue['card']['input']['postal'] : this.localeValue['card']['input']['zip'];
        cardNumberHeight = $('#bank-name-input').parent().height() + 2;
        totalInputLRPadding = 19;
        ccNumFieldWidth = $('#bank-name-input').parent().width() - totalInputLRPadding;

        cardSettings = $('#card_settings');
        if ($('#ccs_card_form')) {
            let $cancelButton, ccInputFocusStyle, ccInputStyles, customerGuid, leaseGuid, localeObj;
            customerGuid = $('#customer_guid').val() || this.customerGuidValue;
            leaseGuid = this.leaseGuidValue;
            localeObj = this.localeValue;
            ccInputStyles = "height: calc(1.5em + 1rem + 2px); margin: 2px auto; width: calc(100% - 4px); line-height: 1.5; padding: 0.75rem 0.5rem; font-size: .9rem; font-weight: 400; font-family: inherit; font-style: normal; border-radius: 0.3rem; border: 1px solid #ced4da; color: #6B7C93; box-sizing: border-box;";
            ccInputFocusStyle = "color: rgb(64, 75, 89); border: 1px solid rgb(92, 179, 253); box-shadow: 0px 0px 8px #4195fc; outline: none;";
            CreditCardServiceV2.form({
                hide_fields: {},
                kount_enabled: false,
                payment_context: 'customer',
                auth_token_endpoint: $('#auth_token_endpoint').val(),
                debit_card_required: false,
                payment_source: 'cp',
                state_select_format: 'short',
                submit_button_text: localeObj['card']['button']['save'],
                ccs_endpoint: $('#ccs_endpoint').val(),
                form_div_id: 'ccs_card_form',
                name: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                country_code: window.countryCode,
                business_entity: cardSettings.data('business-entity'),
                iframe_width: ccNumFieldWidth,
                iframe_height: cardNumberHeight,
                iframe_css_base: ccInputStyles,
                iframe_css_focus: ccInputFocusStyle,
                require_check_box_auth: 'false',
                loading_css: '.cc-loading-mask {}',
                before_submit: (function (_this) {
                    return function () {
                        if (!validateLeaseGuid(leaseGuid, localeObj)) {
                            throw new Error('lease not selected');
                        }
                    };
                })(this),
                onComplete: (function (_this) {
                    return function (response) {
                        setTimeout((function () {
                            return $('#ccservice_response').hide();
                        }), 1);
                        if (response?.status === 'Success') {
                            return saveCreditCard(response.card_guid, customerGuid, leaseGuid, localeObj);
                        } else {
                            return handleCcsFailures(response, localeObj);
                        }
                    };
                })(this)
            });
            async function saveCreditCard (cardGuid, customerGuid, leaseGuid, localeObj) {
                let authenticityToken, root_url, params, url;
                $('#ccs_card_form').append("<input type='hidden' id='cardGuid' value=" + cardGuid + ">");
                authenticityToken = $('#authenticity_token').val();
                params = `card_guid=${cardGuid}&customer_guid=${customerGuid}&lease_guid=${leaseGuid}`;
                root_url = "/payment_method/card"
                url = `${root_url}?${params}`
                const response = await post(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': authenticityToken
                    }
                })

                if (response.ok) {
                    let successParam = `flash_notice=${localeObj['alert']['payment_method_added_successfully']}`;
                    window.location.assign(`/payment_method/dashboard?${successParam}&lease_guid=${leaseGuid}`);
                } else {
                    let error = {
                        'error_code': 'clientside_cp_call_error',
                        'error_message': `${await response.statusText} ${localeObj['error']['occurred_while_attempting_to_add_card']}`,
                        'status_code': await response.status,
                        'status_text': await response.statusText,
                        'url': root_url
                    };
                    await Promise.all([notifyFailureEventService(), notifyErrorNotificationService(error)]);
                    let failureParam = `flash_alert=${localeObj['error']['possible_failure_message']}`;
                    window.location.assign(`/payment_method/dashboard?${failureParam}&lease_guid=${leaseGuid}`);
                }
            }
            function handleCcsFailures (response, localeObj) {
                let error, error_code, error_message;
                error_code = response?.error_code || response?.charge?.error_code || response?.charge?.pre_authorization?.error_code
                error_message = fetchErrorMsg(response, localeObj);
                switch (error_code) {
                    case 'invalid_name':
                    case 'insufficient_funds':
                    case 'rejected':
                    case 'fix_cardnumber':
                    case 'fix_cvv':
                    case 'fix_address':
                    case 'fix_zip':
                    case 'fix_name':
                    case 'fix_expiration':
                    case 'not_allowed_contact_bank':
                    case 'internal_communication_error':
                    case 'not_approved_card_type':
                    case 'invalid_expiry':
                        showCardAlert(error_message);
                        return clearLoaderSpinner();
                    case 'communication_error':
                    case 'unknown':
                    case 'gateway_communication':
                        error = {
                            'error_code': 'ccs_processing_error',
                            'error_message': error_message
                        };
                        return handleError(error, response, error_message, localeObj);
                    default:
                        error = {
                            'error_code': 'ccs_processing_error',
                            'error_message': localeObj['error']['unhandled_error_code'] + error_code || 'NA' + localeObj['error']['unhandled_error_message'] + error_message,
                        };
                        return handleError(error, response, null, localeObj);
                }
            }

            function fetchErrorMsg(response, localeObj) {
                let charge, message, preAuth;
                if (response?.error_message) {
                    return response.error_message;
                }
                charge = response?.charge;
                if (!charge) {
                    return response?.message || localeObj['error']['failed_to_charge_due'];
                }
                preAuth = charge?.pre_authorization;
                message = (preAuth?.response_status === 'C') ? preAuth.response_text :
                    (charge?.response_status === 'C') ? charge.response_text :
                        response.user_message ? response.message || response.user_message :
                            response.message ? response.message : localeObj['error']['failed_to_charge']
                return message;
            }
            function clearLoaderSpinner () {
                return $('.loader-overlay').removeClass('d-block');
            }
            function validateLeaseGuid (leaseGuid, localeObj) {
                if (leaseGuid === "") {
                    showCardAlert(localeObj['card']['error']['lease_required']);
                    return false;
                } else {
                    $('#leaseNumberValidationError').hide();
                    return true;
                }
            }
            function notifyFailureEventService (error) {
                let event_params = {
                    lease_guid: $('#lease_guid').val(),
                    customer_guid: $('#customer_guid').val(),
                    card_guid: $('#cardGuid').val(),
                    error: error
                };
                return NewPaymentMethodFailureEventService.notify(event_params);
            }
             function notifyErrorNotificationService (error, ccsResponse) {
                let errorContext, message;
                message = error['error_code'] + ': ' + error['error_message'];
                errorContext = $.extend({}, error, {
                    ccsResponse: ccsResponse
                });
                return ErrorNotificationService.error(message, errorContext);
            }
            function showCardAlert (alert_message) {
                $('#ccservice_response').hide();
                $('#card-alert').show();
                return $('#card-alert').text(alert_message);
            }

            async function handleError(error, ccsResponse, alert_message, localeObj) {
                if (alert_message == null) {
                    alert_message = localeObj['alert']['try_again'];
                }

                try {
                    await Promise.all([
                        notifyFailureEventService(error),
                        notifyErrorNotificationService(error, ccsResponse)
                    ]);
                    showCardAlert(alert_message);
                } catch (e) {
                    showCardAlert(alert_message);
                } finally {
                    clearLoaderSpinner();
                }
            }

            $('button#ccs_card_formsubmit_button').attr('data-action', 'spinner#navigate')

            $('#ccs_card_form').on('input change', function () {
                return $('#card-alert').css('display', 'none');
            });

            /* Add styling and structure for ccs form */
            $('.ccservice_name_label').text(this.localeValue['card']['input']['name_on_card']);
            $('#credit_card_nameOnCardInput').attr('placeholder', this.localeValue['card']['input']['name']);
            $('#ccs_card_formname_container').addClass('row mb-2 align-items-center');
            $('.ccservice_name_label').wrap("<div class=col-sm-5> <div class=row> <div class='col-sm-11 ml-sm-auto'> </div> </div> </div>");
            $('.ccservice_name_value').wrap("<div class='col-sm-5'></div>");
            $('#credit_card_nameOnCardInput').addClass('form-control form-control-lg');
            $('.ccservice_tokenframe_label').text(this.localeValue['card']['input']['card_number']);
            $('#ccs_card_formtokenframe_container').addClass('row mb-2 align-items-center');
            $('.ccservice_tokenframe_label').wrap("<div class=col-sm-5> <div class=row> <div class='col-sm-11 ml-sm-auto'> </div> </div> </div>");
            $('.ccservice_tokenframe_value').wrap("<div class='col-sm-5'></div>");
            $('#tx_iframe_ccs_card_formtokenframe_value').css('width', '100%');
            $('#tx_iframe_ccs_card_formtokenframe_value').css('border-radius', '0.3rem');
            $('.ccservice_tokenframe_value').css('height', cardNumberHeight + 4);
            $('.ccservice_tokenframe_value').css('width', 'calc(100% + 6px)');
            $('#tx_iframe_ccs_card_formtokenframe_value').css('height', $('#tx_iframe_ccs_card_formtokenframe_value').parent().height());
            $('.ccservice_expiry_label').text(this.localeValue['card']['input']['expiration_date']);
            $('#ccs_card_formexpiry_container').addClass('row mb-2 align-items-center');
            $('.ccservice_expiry_label').wrap("<div class=col-sm-5> <div class=row> <div class='col-sm-11 ml-sm-auto'> </div> </div> </div>");
            $('.ccservice_expiry_value').wrap("<div class='col-sm-5'></div>");
            $('#credit_card_expMonth').addClass('custom-select form-control');
            $('#credit_card_expMonth').wrap("<div class='col-lg-6 col-md-12 col-sm-6 col-6 mr-2 d-inline-block p-0'></div>");
            $('#credit_card_expYear').addClass('custom-select form-control');
            $('#credit_card_expYear').wrap("<div class='col-lg-5 col-md-12 col-sm-5 col-5 d-inline-block p-0'></div>");
            $('.ccservice_cvv_label').text(this.localeValue['card']['input']['security_code']);
            $('#credit_card_cvvInput').attr('placeholder', '123');
            $('#ccs_card_formcvv_container').addClass('row mb-2 align-items-center');
            $('.ccservice_cvv_label').wrap("<div class=col-sm-5> <div class=row> <div class='col-sm-11 ml-sm-auto'> </div> </div> </div>");
            $('.ccservice_cvv_value').wrap("<div class='col-sm-5' id='cvv_value'></div>");
            $('.ccservice_cvv_value').addClass("col-9 col-sm-8 col-md-7 col-lg-9 p-0 d-inline-block");
            $('#cvv_value').append("<div id='security_code' class='col-2 col-sm-4 col-lg-3 d-inline-block'></div>");
            $('#security_code').append($('#security_code_image'));
            $('#security_code_image').show();
            $('#credit_card_cvvInput').addClass('form-control form-control-lg');
            $('.ccservice_address_label').text(this.localeValue['card']['input']['address']);
            $('#credit_card_addressInput').attr('placeholder', this.localeValue['card']['input']['address']);
            $('#ccs_card_formaddress_container').addClass('row mb-2 align-items-center');
            $('.ccservice_address_label').wrap("<div class=col-sm-5> <div class=row> <div class='col-sm-11 ml-sm-auto'> </div> </div> </div>");
            $('.ccservice_address_value').wrap("<div class='col-sm-5'></div>");
            $('#credit_card_addressInput').addClass('form-control form-control-lg');
            $('.ccservice_city_label').text(this.localeValue['card']['input']['city']);
            $('#credit_card_cityInput').attr('placeholder', this.localeValue['card']['input']['city']);
            $('#ccs_card_formcity_container').addClass('row mb-2 align-items-center');
            $('.ccservice_city_label').wrap("<div class=col-sm-5> <div class=row> <div class='col-sm-11 ml-sm-auto'> </div> </div> </div>");
            $('.ccservice_city_value').wrap("<div class='col-sm-5'></div>");
            $('#credit_card_cityInput').addClass('form-control form-control-lg');
            $('.ccservice_state_label').text(state_label);
            $('#credit_card_stateInput').attr('placeholder', 'State Code (Ex. UT, NY, MS)');
            $('#credit_card_stateInput').attr('maxlength', 2);
            $('#ccs_card_formstate_container').addClass('row mb-2 align-items-center');
            $('.ccservice_state_label').wrap("<div class=col-sm-5> <div class=row> <div class='col-sm-11 ml-sm-auto'> </div> </div> </div>");
            $('.ccservice_state_value').wrap("<div class='col-sm-5'></div>");
            $('#credit_card_stateInput').addClass('form-control form-control-lg');
            $('.ccservice_zip_label').text(zip_label);
            $('#credit_card_zipInput').attr('placeholder', zip_label);
            $('#ccs_card_formzip_container').addClass('row mb-2 align-items-center');
            $('.ccservice_zip_label').wrap("<div class=col-sm-5> <div class=row> <div class='col-sm-11 ml-sm-auto'> </div> </div> </div>");
            $('.ccservice_zip_value').wrap("<div class='col-sm-5'></div>");
            $('#credit_card_zipInput').addClass('form-control form-control-lg');
            $('.ccservice_buttons').addClass('mt-4');
            $('#ccs_card_formsubmit_button').addClass('btn btn-primary btn-sm mr-1');
            $('#ccs_card_formsubmit_button').wrap('<div class="row mb-2"></div>');
            $('#ccs_card_formsubmit_button').wrap('<div class="offset-sm-5 col-sm-7 buttons"></div>');
            $cancelButton = $("<a href='/payment_method/dashboard?lease_guid=" + this.leaseGuidValue + "&lang=" + window.i18n.locale + "' id='card-cancel' class='btn btn-outline-primary btn-sm'>" + this.localeValue['card']['button']['cancel'] + "</a>");
            return $cancelButton.appendTo($('#ccs_card_formsubmit_button').parent());
        }
    }

    disconnect() {
        this.observer.disconnect();
    }
}
